/* In this project, style.sass (this file) is used for styles that will be used repeatedly throughout the project and imported into other css files with SASS imports. DO NOT use this file to add styles that will only apply to one page of the application. Instead, create a new sass file that is particular to that page. */
/* autoprefixer grid: autoplace */
@font-face {
  src: url("../fonts/manrope.ttf");
  font-family: "manrope";
}
@font-face {
  src: url("../fonts/manrope-bold.ttf");
  font-family: manrope-bold;
}
@font-face {
  src: url("../fonts/futura.otf"), url("../fonts/futura.woff") format("woff");
  font-family: "futura-bold";
}
body {
  font-family: manrope;
  margin: 0;
  font-size: 1.1em;
  color: #121619;
  overflow-x: hidden;
}
body p {
  margin-top: 0.25em;
}
@media screen and (max-width: 850px) {
  body {
    font-size: 1em;
  }
}

h1, h2, h3 {
  font-family: futura-bold;
  margin-bottom: 0;
}
@media screen and (max-width: 850px) {
  h1, h2, h3 {
    font-size: 1.5em !important;
  }
}

p.bold, span.bold {
  font-family: manrope-bold;
}

.gradient-underline {
  background-image: linear-gradient(to right, #F7921E, #B337FF);
  background-size: 100% 10px;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
  padding-bottom: 6px;
}

li {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.container {
  height: fit-content;
}

.centered {
  text-align: center;
}

.gray-bg {
  background-color: #f5f5f5;
}

.gradient-bg {
  padding: 4em 2em;
  background-image: linear-gradient(to right, #F7921E, #B337FF);
}

.gradient-ul {
  padding-bottom: 0.2em;
  border-bottom: 8px solid;
  border-image: linear-gradient(to right, #F7921E, #B337FF) 1;
}

.gradient {
  background-image: linear-gradient(to right, #F7921E, #B337FF);
}

.hide {
  display: none;
  visibility: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.split {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  padding-top: 1.5em;
  padding-bottom: 2em;
}
.split .text {
  justify-self: center;
  align-self: center;
  max-width: 80%;
  box-sizing: border-box;
}
.split .image {
  justify-self: center;
  text-align: center;
  align-self: center;
}
.split .image img {
  width: 75%;
}
@media screen and (max-width: 850px) {
  .split {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
  .split .text {
    grid-row: 1;
  }
}

.text .icons {
  max-width: 100%;
}
.text .icons img {
  margin: 0 0.2em;
  height: 30px;
  width: auto;
  vertical-align: middle;
}
@media screen and (max-width: 850px) {
  .text .icons img {
    height: 15px;
  }
}

.slide-in {
  transition: 0.5s;
}

.slide-out {
  transition: 0.5s;
}

.button-outline {
  background-color: transparent;
  border: 2px solid white;
}
.button-outline.black {
  border: 2px solid #000000;
  color: #000000;
}
.button-outline.black:hover {
  background-color: rgba(127, 127, 127, 0.1);
}
.button-outline:hover {
  background-color: rgba(127, 127, 127, 0.5);
}

.bold {
  font-weight: 800;
  font-family: manrope-bold;
}

.bolder {
  font-weight: 700;
}

button {
  border: none;
  box-sizing: border-box;
  padding: 0.6rem 2rem;
  border-radius: 5px;
  transition: 0.4s;
  font-family: manrope-bold;
  font-size: 1em;
  color: white;
  cursor: pointer;
}
button.smaller {
  padding: calc(0.6rem / 2) calc(2rem / 2);
}
@media screen and (max-width: 900px) {
  button {
    padding: calc(0.6rem / 2) calc(2rem / 2);
  }
}

.divider-line {
  background-color: gray;
  width: 100%;
  justify-self: center;
  height: 1px;
}

.button-orange {
  background-color: #F7921E;
  border: 2px solid #F7921E;
}
.button-orange:hover {
  background-color: #d67608;
  border-color: #d67608;
}

.button-violet {
  background-color: #3F334D;
  border: 2px solid #3F334D;
}
.button-violet:hover {
  background-color: #32293e;
  border-color: #32293e;
}

.button-black {
  background-color: #000000;
  border: 2px solid #000000;
}
.button-black:hover {
  background-color: black;
  border-color: black;
}

#nav {
  height: 5.5vh;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  margin: 0;
  padding: 1rem;
  font-family: manrope;
  border-bottom: 2px solid lightgray;
  background-color: white;
  z-index: 100;
}
@media screen and (max-width: 850px) {
  #nav {
    height: 6vh;
    padding: 1em;
  }
}
#nav a {
  height: 100%;
  width: fit-content;
  text-decoration: none;
  color: #121619;
}
#nav a img {
  height: 5.5vh;
}
@media screen and (max-width: 850px) {
  #nav a img {
    height: 100%;
  }
}
#nav #options {
  justify-self: end;
  margin: auto 0 auto 2rem;
  width: auto;
  width: fit-content;
  height: fit-content;
}
#nav #options span, #nav #options button {
  margin: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
#nav #options button {
  padding: 0.6rem 2rem;
  margin-right: 4em;
}
#nav #options #programs {
  cursor: pointer;
  position: relative;
}
#nav #options #programs #program-items {
  margin-left: -50%;
  text-align: center;
  height: fit-content;
  position: absolute;
  border-radius: 0.5em;
  width: 250%;
  background-color: #3F334D;
  border: 1px solid white;
}
#nav #options #programs #program-items .program-item {
  padding: 2em 0.8em;
}
#nav #options #programs #program-items .program-item p {
  margin: 0;
}
#nav #options #programs #program-items #for-kids-teens, #nav #options #programs #program-items #for-kids-teens:hover {
  border-radius: 0 0 0.5em 0.5em;
}
#nav #options #programs #program-items #for-edu, #nav #options #programs #program-items #for-edu:hover {
  border-radius: 0.5em 0.5em 0 0;
}
#nav #options #programs #program-items a {
  color: white;
  font-size: 0.75em;
  font-weight: bold;
}
#nav #options #programs #program-items .program-item:hover {
  background-color: #6e627a;
}
@media screen and (max-width: 850px) {
  #nav #options {
    display: none;
  }
}
#nav #hamburger {
  height: fit-content;
  justify-self: right;
  align-self: center;
}
#nav #hamburger .spin-right {
  transform: translateY(0.4em) rotate(45deg);
  transition: 0.2s;
}
#nav #hamburger .spin-left {
  transform: translateY(-0.4em) rotate(-45deg);
  transition: 0.2s;
}
#nav #hamburger .bar {
  font-size: 0.8em;
  height: 0.25em;
  margin-bottom: 0.4em;
  margin-top: 0.6em;
  width: 3.3em;
  background-color: #3F334D;
}
#nav #hamburger .cancel-x {
  font-size: 3em;
  text-align: center;
}
@media screen and (min-width: 851px) {
  #nav #hamburger {
    display: none;
  }
}

#mobile-nav {
  position: fixed;
  top: -200vh;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  margin: 0;
  padding-left: 0;
  font-weight: 700;
  font-size: 1.5em;
  color: #000000;
}
#mobile-nav p {
  font-size: 0.7em;
  font-weight: 600;
  font-family: manrope;
}
#mobile-nav a {
  text-decoration: none;
  color: #000000;
}
#mobile-nav h2 {
  margin: 0;
}
#mobile-nav button {
  font-weight: 700;
  font-size: 0.8em;
  padding: 1rem 2.5rem;
}
#mobile-nav #mobile-nav-grid {
  display: grid;
  margin-top: 5.5vh;
  justify-items: center;
  align-items: end;
  gap: 3vh 0;
}
#mobile-nav #mobile-nav-grid #mobile-programs {
  text-align: center;
}
#mobile-nav #mobile-nav-grid #mobile-programs #program-items-mobile {
  font-size: 0.75em;
}

.slide-in {
  top: 0 !important;
}