@import 'style.sass'
@import 'vars.sass'
@include button("orange")
@include button("violet")
@include button("black")
#nav
  height: $nav-height
  position: sticky
  top: 0
  // width: 100vw
  display: grid
  grid-template-columns: 50% 50%
  grid-template-rows: 100%
  margin: 0
  padding: 1rem
  font-family: manrope
  border-bottom: 2px solid lightgray
  background-color: white
  z-index: 100
  @media screen and (max-width: 850px)
    height: 6vh
    padding: 1em
  a
    height: 100%
    width: fit-content
    text-decoration: none
    color: #121619
    img
      height: 5.5vh
      @media screen and (max-width: 850px)
        height: 100%
  #options
    justify-self: end
    margin: auto 0 auto 2rem
    width: auto
    width: fit-content
    height: fit-content
    span,button
      margin: 1em
      margin-top: 0
      margin-bottom: 0
    button
      padding: $btn-tb-padding $btn-lr-padding
      margin-right: 4em
    #programs
      cursor: pointer
      position: relative
      #program-items
        margin-left: -50%
        text-align: center
        height: fit-content
        position: absolute
        border-radius: .5em
        width: 250%
        background-color: $violet
        border: 1px solid white
        .program-item
          padding: 2em .8em
          p
            margin: 0
        #for-kids-teens, #for-kids-teens:hover
          border-radius: 0 0 .5em .5em
        #for-edu, #for-edu:hover
          border-radius: .5em .5em 0 0
        
        a
          color: white
          font-size: .75em
          font-weight: bold
        .program-item:hover
          background-color: #6e627a
    @media screen and (max-width: 850px)
      display: none
  #hamburger
    height: fit-content
    justify-self: right
    align-self: center
    // margin-right: 2em
    .spin-right
      transform: translateY(.4em) rotate(45deg)
      transition: .2s
    .spin-left
      transform: translateY(-.4em) rotate(-45deg)
      transition: .2s
    .bar
      font-size: .8em
      height: .25em
      margin-bottom: .4em
      margin-top: .6em
      width: 3.3em
      background-color: #3F334D
    .cancel-x
      font-size: 3em
      text-align: center
    @media screen and (min-width: 851px)
      display: none
#mobile-nav
  position: fixed
  top: -200vh
  height: 100vh
  width: 100vw
  z-index: 50
  margin: 0
  padding-left: 0
  font-weight: 700
  font-size: 1.5em
  color: $black
  p
    font-size: .7em
    font-weight: 600
    font-family: manrope
  a
    text-decoration: none
    color: $black
  h2
    margin: 0
  button
    font-weight: 700
    font-size: .8em
    padding: 1rem 2.5rem
  #mobile-nav-grid
    display: grid
    margin-top: $nav-height
    justify-items: center
    align-items: end
    gap: 3vh 0
    #mobile-programs
      text-align: center
      #program-items-mobile
        font-size: .75em

.slide-in
  top: 0 !important
